// src/utils/analytics/index.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Initialize Google Analytics
export const initGA = () => {
  if (!process.env.REACT_APP_GA_MEASUREMENT_ID) {
    console.warn("Google Analytics Measurement ID not found");
    return;
  }

  // Load the Google Analytics script
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`;
  document.head.appendChild(script);

  // Initialize gtag
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag("js", new Date());
  gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID, {
    page_path: window.location.pathname,
    user_properties: {
      user_type: localStorage.getItem("token") ? "registered" : "guest",
    },
  });
};

// Hook to track page views
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag) return;

    window.gtag("event", "page_view", {
      page_title: document.title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
      user_status: localStorage.getItem("token") ? "logged_in" : "anonymous",
      time_of_day: new Date().getHours(),
      day_of_week: new Date().getDay(),
    });
  }, [location]);
};
