// src/components/TagBadge.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { X, Plus, Tag as TagIcon } from "lucide-react";

// Define the tag shape that will be used in both components
const tagShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
});

const TagBadge = ({ tag, onRemove, className = "" }) => (
  <span
    className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm ${className}`}
    style={{ backgroundColor: `${tag.color}20`, color: tag.color }}
  >
    <TagIcon size={14} />
    {tag.name}
    {onRemove && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onRemove(tag);
        }}
        className="hover:bg-white/20 rounded-full p-0.5"
      >
        <X size={14} />
      </button>
    )}
  </span>
);

TagBadge.propTypes = {
  tag: tagShape.isRequired,
  onRemove: PropTypes.func,
  className: PropTypes.string,
};

const TagModal = ({ isOpen, onClose, onAddTag, existingTags = [], currentTags = [], onRemoveTag }) => {
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("#3B82F6");
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setTagName(value);
    
    if (value.trim()) {
      // First show existing tags that match the input
      const filteredSuggestions = existingTags
        .filter(tag => 
          tag.toLowerCase().includes(value.toLowerCase()) && 
          !currentTags.some(t => t.name === tag)
        )
        .slice(0, 5); // Limit to 5 suggestions
      setSuggestions(filteredSuggestions);
    } else {
      // If input is empty, show all existing tags that aren't already added
      const availableTags = existingTags
        .filter(tag => !currentTags.some(t => t.name === tag))
        .slice(0, 5);
      setSuggestions(availableTags);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setTagName(suggestion);
    setSuggestions([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tagName.trim()) {
      onAddTag({ name: tagName.trim(), color: tagColor });
      setTagName("");
      setTagColor("#3B82F6");
      setSuggestions([]);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-white">Manage Tags</h3>
          <button
            onClick={onClose}
            className="text-white/60 hover:text-white"
          >
            <X size={20} />
          </button>
        </div>

        {/* Current Tags */}
        <div className="mb-6">
          <h4 className="text-sm font-medium text-white/80 mb-3">Current Tags</h4>
          <div className="flex flex-wrap gap-2">
            {currentTags.map((tag) => (
              <TagBadge
                key={tag.name}
                tag={tag}
                onRemove={onRemoveTag}
              />
            ))}
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-white/80 mb-2">
              Add New Tag
            </label>
            <div className="flex gap-2">
              <div className="relative flex-1">
                <input
                  type="text"
                  value={tagName}
                  onChange={handleInputChange}
                  placeholder="Enter tag name"
                  className="w-full bg-white/5 text-white border border-white/10 rounded px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500"
                  autoFocus
                />
                {suggestions.length > 0 && (
                  <div className="absolute top-full left-0 right-0 mt-1 bg-gray-900/95 border border-white/10 rounded-lg shadow-lg z-10">
                    {suggestions.map((suggestion) => (
                      <button
                        key={suggestion}
                        type="button"
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="w-full px-3 py-2 text-left text-sm text-white/80 hover:bg-white/10 hover:text-white transition-colors"
                      >
                        {suggestion}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex items-center">
                <input
                  type="color"
                  value={tagColor}
                  onChange={(e) => setTagColor(e.target.value)}
                  className="w-10 h-10 rounded cursor-pointer"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-white/60 hover:text-white"
            >
              Close
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-purple-500 text-white rounded text-sm hover:bg-purple-600"
            >
              Add Tag
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

TagModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  existingTags: PropTypes.arrayOf(PropTypes.string),
  currentTags: PropTypes.arrayOf(tagShape),
};

TagModal.defaultProps = {
  existingTags: [],
  currentTags: [],
};

const TagInput = ({ onAddTag, existingTags = [], currentTags = [], onRemoveTag }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!isModalOpen) {
    return (
      <button
        onClick={() => setIsModalOpen(true)}
        className="inline-flex items-center gap-1 text-sm text-white/60 hover:text-white"
      >
        <Plus size={16} />
        {currentTags.length === 0 ? "Add Tag" : ""}
      </button>
    );
  }

  return (
    <TagModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onAddTag={onAddTag}
      onRemoveTag={onRemoveTag}
      existingTags={existingTags}
      currentTags={currentTags}
    />
  );
};

TagInput.propTypes = {
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  existingTags: PropTypes.arrayOf(PropTypes.string),
  currentTags: PropTypes.arrayOf(tagShape),
};

TagInput.defaultProps = {
  existingTags: [],
  currentTags: [],
};

// Export both components and the tag shape for reuse
export { TagBadge, TagInput, tagShape };
