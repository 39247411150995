import React, { useState, useEffect } from "react";
import { Check, ArrowDown, ArrowUp } from "lucide-react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./context/AuthContext";
import PricingTable from "./PricingTable";

const PLAN_RANKS = {
  FREE: 0,
  PRO: 1,
  BULK: 2,
  ENTERPRISE: 3,
};

const Plans = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const { userInfo, isAuthenticated } = useAuth();

  const currentPlan = isAuthenticated ? userInfo?.plan?.type || "FREE" : null;

  console.log("Current Plan:", currentPlan);
  console.log("Plan Details:", userInfo?.plan);

  const getAnnualPrice = (monthlyPrice) => {
    const annualBase = monthlyPrice * 12;
    const discount = 0.2;
    return (annualBase * (1 - discount)).toFixed(2);
  };

  const proPriceMonthly = 9.99;
  const bulkPriceMonthly = 99.0;

  const proPrice = isAnnual ? getAnnualPrice(proPriceMonthly) : proPriceMonthly;
  const bulkPrice = isAnnual
    ? getAnnualPrice(bulkPriceMonthly)
    : bulkPriceMonthly;

  useEffect(() => {
    console.log("User Info:", userInfo);
  }, [userInfo]);

  return (
    <>
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-4">Shrtly Plans</h1>
          <p className="text-xl text-white/60 mb-8">
            Find a plan that meets your needs
          </p>

          {isAuthenticated && userInfo?.plan && (
            <div className="mb-8 bg-white/5 p-6 rounded-lg border border-white/10">
              <h2 className="text-xl font-bold text-white mb-4">
                Current Plan Status
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <p className="text-white/60">Started On</p>
                  <p className="text-white">
                    {new Date(userInfo.plan.startDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center gap-4 mb-12">
            <span className="text-white/60">Billing cycle:</span>
            <span
              className={
                !isAnnual ? "text-white font-semibold" : "text-white/60"
              }
            >
              Monthly
            </span>
            <button
              className={`w-16 h-8 rounded-full p-1 transition-colors duration-300 ${
                isAnnual ? "bg-blue-500" : "bg-white/10"
              }`}
              onClick={() => setIsAnnual(!isAnnual)}
            >
              <div
                className={`w-6 h-6 rounded-full bg-white transform transition-transform duration-300 ${
                  isAnnual ? "translate-x-8" : ""
                }`}
              />
            </button>
            <span
              className={
                isAnnual ? "text-white font-semibold" : "text-white/60"
              }
            >
              Annual
            </span>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {PLAN_CARDS.map((planConfig) => {
              const isCurrentPlan = currentPlan === planConfig.planType;
              const currentPlanRank = currentPlan
                ? PLAN_RANKS[currentPlan]
                : null;
              const planRank = PLAN_RANKS[planConfig.planType];

              let price = planConfig.price;
              if (typeof price === "function") {
                price = price(isAnnual, proPrice, bulkPrice);
              }

              return (
                <div
                  key={planConfig.planType}
                  className={`bg-white/5 rounded-lg p-6 transition-all duration-300 hover:bg-white/10 relative ${
                    planConfig.isPopular
                      ? "border-2 border-blue-500"
                      : "border border-white/10"
                  } ${isCurrentPlan ? "ring-2 ring-green-500" : ""} ${
                    planConfig.planType !== "FREE"
                      ? "blur-sm pointer-events-none"
                      : ""
                  }`}
                >
                  {/* {planConfig.isPopular && (
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                      <span className="bg-blue-500 text-white text-sm px-4 py-1 rounded-full">
                        Most Popular
                      </span>
                    </div>
                  )} */}
                  {isCurrentPlan && (
                    <div className="absolute -top-4 right-4">
                      <span className="bg-green-500 text-white text-sm px-4 py-1 rounded-full">
                        Current Plan
                      </span>
                    </div>
                  )}
                  <h3 className="text-xl font-bold text-white mb-4">
                    {planConfig.title}
                  </h3>
                  <div className="mb-6">
                    <span className="text-4xl font-bold text-white">
                      {price}
                    </span>
                    <span className="text-white/60">{planConfig.period}</span>
                    {planConfig.getYearlyPrice && (
                      <div className="text-sm text-white/60 mt-1">
                        {planConfig.getYearlyPrice(
                          isAnnual,
                          proPrice,
                          bulkPrice
                        )}
                        <span className="text-green-400 ml-2">Save 20%</span>
                      </div>
                    )}
                  </div>

                  {planConfig.minValue && (
                    <div className="flex justify-between items-center mb-6 text-white/60">
                      <span>{planConfig.minValue}</span>
                      <div className="w-48 h-2 bg-white/10 rounded-full">
                        <div
                          className="h-2 bg-blue-500 rounded-full"
                          style={{
                            width: planConfig.isPopular ? "50%" : "25%",
                          }}
                        ></div>
                      </div>
                      <span>{planConfig.maxValue}</span>
                    </div>
                  )}

                  <p className="mb-6 text-white/60">{planConfig.description}</p>

                  <div className="space-y-3 mb-8">
                    {planConfig.features.map((feature, index) => (
                      <div key={index} className="flex items-start gap-2">
                        <Check className="w-5 h-5 text-green-400 mt-1 flex-shrink-0" />
                        <span className="text-white/80">{feature}</span>
                      </div>
                    ))}
                  </div>

                  {renderButton(
                    isCurrentPlan,
                    currentPlanRank,
                    planRank,
                    planConfig.buttonText
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <PricingTable></PricingTable>
    </>
  );
};

// Helper function to render the button
const renderButton = (
  isCurrentPlan,
  currentPlanRank,
  planRank,
  defaultText
) => {
  if (isCurrentPlan) {
    return (
      <button
        className="w-full py-2 px-4 rounded bg-green-500 text-white cursor-default"
        disabled
      >
        Current Plan
      </button>
    );
  }

  if (currentPlanRank === null) {
    if (defaultText === "Get Started") {
      return (
        <button
          className="w-full py-2 px-4 rounded bg-blue-500 hover:bg-blue-600 text-white transition-colors"
          onClick={() =>
            toast.info("Please login or create a new account to get started!")
          }
        >
          {defaultText}
        </button>
      );
    }

    return (
      <button
        className="w-full py-2 px-4 rounded bg-blue-500 hover:bg-blue-600 text-white transition-colors"
        onClick={() => toast.warn("This plan is not ready yet. Coming soon!")}
      >
        {defaultText}
      </button>
    );
  }

  const isUpgrade = planRank > currentPlanRank;
  return (
    <button
      className={`w-full py-2 px-4 rounded transition-colors ${
        isUpgrade
          ? "bg-blue-500 hover:bg-blue-600 text-white"
          : "bg-yellow-500 hover:bg-yellow-600 text-white"
      }`}
      onClick={() =>
        toast.warn(
          `${isUpgrade ? "Upgrade" : "Downgrade"} feature coming soon!`
        )
      }
    >
      <span className="mr-2">
        {isUpgrade ? (
          <ArrowUp className="inline w-4 h-4" />
        ) : (
          <ArrowDown className="inline w-4 h-4" />
        )}
      </span>
      {isUpgrade ? "Upgrade" : "Downgrade"}
    </button>
  );
};

// Plan configurations
const PLAN_CARDS = [
  {
    planType: "FREE",
    title: "Free",
    price: "$0",
    period: "/mo",
    minValue: "0",
    maxValue: "250",
    description: "Start shortening URLs for free",
    features: [
      "Up to 250 links",
      "Basic Analytics",
      "Links never expire",
      "Standard support",
      "Basic link management",
    ],
    buttonText: "Get Started",
  },
  {
    planType: "PRO",
    title: "Pro",
    price: (isAnnual, proPrice) =>
      `$${isAnnual ? (proPrice / 12).toFixed(2) : proPrice}`,
    period: "/mo",
    getYearlyPrice: (isAnnual, proPrice) =>
      isAnnual ? `($${proPrice}/yr)` : null,
    minValue: "250",
    maxValue: "20K+",
    description: "500 Links with Unlimited Trackable Clicks",
    features: [
      "Advanced Analytics",
      "Advanced Link Management",
      "Shorten URLs Using Branded Domains",
      "Link Editing & Deletion",
      "Custom Link Expiration Dates",
    ],
    buttonText: "Select",
    isPopular: true,
  },
  {
    planType: "BULK",
    title: "Bulk 20K",
    price: (isAnnual, _, bulkPrice) =>
      `$${isAnnual ? (bulkPrice / 12).toFixed(2) : bulkPrice}`,
    period: "/mo",
    getYearlyPrice: (isAnnual, _, bulkPrice) =>
      isAnnual ? `($${bulkPrice}/yr)` : null,
    minValue: "20K",
    maxValue: "5M+",
    description: "100K Links and Track up to 100K Clicks",
    features: [
      "All Pro features included",
      "90-day default link expiration",
      "Track up to 100K clicks",
      "100K branded short links",
      "Priority support",
    ],
    buttonText: "Select",
  },
  {
    planType: "ENTERPRISE",
    title: "Enterprise",
    price: "Custom",
    description:
      "Need a larger limit, dedicated customer support, custom solutions, or specific compliance requirements?",
    features: [
      "Dedicated account manager",
      "Custom link limits",
      "Advanced security features",
      "Custom integration support",
      "SLA guarantee",
    ],
    buttonText: "Contact Us",
  },
];

export default Plans;
