// context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [closeDropdown, setCloseDropdown] = useState(null);

  useEffect(() => {
    // Check for token and user info on mount
    const token = localStorage.getItem("token");
    const storedUserInfo = localStorage.getItem("userInfo");

    if (token && storedUserInfo) {
      setIsAuthenticated(true);
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []);

  const login = (data) => {
    // Store token
    localStorage.setItem("token", data.token);

    // Create user info object from response
    const userInfo = {
      email: data.userEmail,
      authType: data.authType,
      plan: data.plan || { type: "FREE" },
    };

    // Store user info
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    // Update state
    setIsAuthenticated(true);
    setUserInfo(userInfo);

    // Close dropdown if callback exists
    if (closeDropdown) {
      closeDropdown();
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setIsAuthenticated(false);
    setUserInfo(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userInfo,
        login,
        logout,
        setCloseDropdown,
        updateUserInfo: (newInfo) => {
          const updatedInfo = { ...userInfo, ...newInfo };
          localStorage.setItem("userInfo", JSON.stringify(updatedInfo));
          setUserInfo(updatedInfo);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
