import React from "react";

const PublicTermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
      {/* Header Section */}
      <div className="w-full py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-4">
            Terms of Service
          </h1>
          <p className="text-xl text-white/60">
            Please read these terms carefully before using our service
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 pb-16">
        <div className="space-y-6">
          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              1. Acceptance of Terms
            </h2>
            <p className="text-white/60">
              By accessing and using Shrtly&apos;s services, you acknowledge
              that you have read, understood, and agree to be bound by these
              Terms of Service.
            </p>
          </section>

          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              2. Description of Service
            </h2>
            <p className="text-white/60">
              Shrtly provides URL shortening services that allow users to create
              shortened versions of long URLs. The service includes link
              management, analytics, and customization features as described on
              our Features page.
            </p>
          </section>

          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              3. User Responsibilities
            </h2>
            <p className="text-white/60 mb-4">
              Users are responsible for all activities conducted through their
              accounts and must:
            </p>
            <ul className="list-disc pl-6 text-white/60 space-y-2">
              <li>Maintain the security of their account credentials</li>
              <li>Comply with all applicable laws and regulations</li>
              <li>
                Not use the service for any illegal or unauthorized purposes
              </li>
              <li>Not attempt to circumvent any security features</li>
            </ul>
          </section>

          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              4. Privacy and Data Protection
            </h2>
            <p className="text-white/60">
              Your privacy is important to us. Our collection and use of
              personal information is governed by our Privacy Policy, which is
              incorporated into these Terms of Service.
            </p>
          </section>

          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              5. Service Modifications
            </h2>
            <p className="text-white/60">
              We reserve the right to modify, suspend, or discontinue any part
              of our service at any time, with or without notice. We will not be
              liable to you or any third party for any such modifications.
            </p>
          </section>

          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              6. Termination
            </h2>
            <p className="text-white/60">
              We reserve the right to terminate or suspend access to our service
              immediately, without prior notice, for any violation of these
              Terms of Service or for any other reason we deem appropriate.
            </p>
          </section>

          <section className="bg-white/5 p-6 rounded-lg border border-white/10">
            <h2 className="text-2xl font-semibold text-white mb-4">
              7. Contact Information
            </h2>
            <p className="text-white/60">
              If you have any questions about these Terms of Service, please
              contact us at{" "}
              <a
                href="mailto:support@shrtly.net"
                className="text-blue-400 hover:text-blue-300"
              >
                support@shrtly.net
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PublicTermsOfService;
