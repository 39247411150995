import React, { useState } from "react";
import { Check, X } from "lucide-react";
import { toast } from "react-toastify";

const PricingTable = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  // For plan headers
  const renderPlanColumn = (content, index) => {
    const shouldBlur = index > 1;
    return (
      <div
        className={`text-center text-white ${
          shouldBlur ? "blur-sm pointer-events-none" : ""
        }`}
      >
        {content}
      </div>
    );
  };

  // Helper function to render feature values with blur effect
  const renderFeatureValue = (value, columnIndex) => {
    // Only the free plan column (index 0) should be visible
    const shouldBlur = columnIndex !== 0;
    return (
      <div
        className={`text-center text-white ${
          shouldBlur ? "blur-sm pointer-events-none" : ""
        }`}
      >
        {value === "✓" ? (
          <Check className="mx-auto text-green-400" size={20} />
        ) : value === "×" ? (
          <X className="mx-auto text-red-400" size={20} />
        ) : (
          <span>{value}</span>
        )}
      </div>
    );
  };

  const features = {
    monthlyVolumes: {
      title: "Monthly Volumes",
      subtitle: "Shorten URLs to make them more clickable",
      items: [
        {
          name: "Links with Unlimited Trackable Clicks",
          info: "Track clicks without limits",
          values: ["250", "500", "2500", "Custom"],
        },
        {
          name: "Click Analytics on All Other Links",
          info: "Monitor performance",
          values: ["×", "9,500", "100,000", "Custom"],
        },
        {
          name: "Branded Links",
          info: "Custom branding",
          values: ["0", "10,000", "100,000", "Unlimited"],
        },
        // {
        //   name: "Link Shortening Limit",
        //   info: "Monthly capacity",
        //   values: ["10", "10,000", "100,000", "Custom"],
        // },
      ],
    },
    linkManagement: {
      title: "Link Management",
      subtitle: "Find and manage all your vital links",
      items: [
        {
          name: "Tag Links",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Sort Links",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Customize URL Aliases",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Rename Link Aliases",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Edit Link Destinations",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Delete Links",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Default Link Expiration",
          values: ["Never Expires", "90 Days", "180 Days", "Custom"],
        },
        {
          name: "Set Link Expiration Dates",
          values: ["×", "✓", "✓", "✓"],
        },
      ],
    },
    branding: {
      title: "Link Branding and Customization",
      subtitle: "Keep your branding top-of-mind",
      items: [
        {
          name: "Branded Domains",
          values: ["0", "3", "10", "Custom"],
        },
        {
          name: "Shorten Links with Custom Domains",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "1 Free Year on Selected Domains",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Bring Your Domains to Shrtly",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Bring Your Subdomains to Shrtly",
          values: ["×", "✓", "✓", "✓"],
        },
      ],
    },
    analytics: {
      title: "Monitoring & Analytics",
      subtitle: "Understand your audiences",
      items: [
        {
          name: "Total Clicks",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Analytics Dashboard",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by Device Type",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by OS",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by Browser",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Link Analytics History",
          values: ["×", "2 Years", "2 Years", "2 Years"],
        },

        {
          name: "Clicks over Time",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by Referrer",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by Geography",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by Device Language",
          values: ["×", "✓", "✓", "✓"],
        },
        {
          name: "Clicks by Popular Days & Times",
          values: ["×", "✓", "✓", "✓"],
        },
      ],
    },
    support: {
      title: "Customer Support",
      subtitle: "Reach our experts",
      items: [
        {
          name: "Customer Support Tickets",
          values: ["✓", "✓", "✓", "✓"],
        },
        {
          name: "Dedicated Account Manager",
          values: ["×", "×", "×", "✓"],
        },
        {
          name: "Emergency Phone Support",
          values: ["×", "×", "×", "✓"],
        },
        {
          name: "SLA Uptime Guarantee",
          values: ["×", "×", "×", "99.9%"],
        },
      ],
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="mb-12">
          <h1 className="text-4xl font-bold mb-4 text-white">
            Feature Breakdown
          </h1>
          <p className="text-white/60">
            Take a detailed look at everything that&apos;s included in our
            subscription plans so you can find the one that works for you.
          </p>

          <div className="flex items-center gap-4 mt-6 text-white">
            <span className="text-white/60">Billing cycle:</span>
            <div className="flex items-center gap-2">
              <span className={!isAnnual ? "font-semibold" : "text-white/60"}>
                Monthly
              </span>
              <button
                onClick={() => setIsAnnual(!isAnnual)}
                className={`w-16 h-8 rounded-full p-1 transition-colors ${
                  isAnnual ? "bg-blue-500" : "bg-white/10"
                }`}
              >
                <div
                  className={`w-6 h-6 bg-white rounded-full transform transition-transform ${
                    isAnnual ? "translate-x-8" : ""
                  }`}
                />
              </button>
              <span className={isAnnual ? "font-semibold" : "text-white/60"}>
                Annual
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4 mb-8">
          <div className="col-span-1"></div>
          {renderPlanColumn(
            <>
              <h3 className="font-bold mb-2">Free</h3>
              <p className="text-2xl font-bold">
                $0.00<span className="text-sm text-white/60">/mo</span>
              </p>
            </>,
            1
          )}
          {renderPlanColumn(
            <>
              <h3 className="font-bold mb-2">Pro</h3>
              <p className="text-2xl font-bold">
                ${isAnnual ? "9.99" : "11.99"}
                <span className="text-sm text-white/60">/mo</span>
              </p>
              {isAnnual && <p className="text-sm text-green-400">Save 20%</p>}
            </>,
            2
          )}
          {renderPlanColumn(
            <>
              <h3 className="font-bold mb-2">Bulk 100K</h3>
              <p className="text-2xl font-bold">
                ${isAnnual ? "99.00" : "119.00"}
                <span className="text-sm text-white/60">/mo</span>
              </p>
              {isAnnual && <p className="text-sm text-green-400">Save 20%</p>}
            </>,
            3
          )}
          {renderPlanColumn(
            <>
              <h3 className="font-bold mb-2">Enterprise</h3>
              <p className="text-2xl font-bold">Custom</p>
            </>,
            4
          )}
        </div>

        {Object.entries(features).map(([key, section]) => (
          <div key={key} className="mb-12">
            <div className="bg-white/5 p-4 mb-6 rounded-lg">
              <h2 className="text-xl font-bold mb-1 text-white">
                {section.title}
              </h2>
              <p className="text-white/60">{section.subtitle}</p>
            </div>

            <div className="space-y-4">
              {section.items.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-5 gap-4 items-center"
                >
                  <div className="col-span-1">
                    <span className="text-white">{item.name}</span>
                    {item.info && (
                      <p className="text-sm text-white/60">{item.info}</p>
                    )}
                  </div>
                  {item.values.map((value, valueIndex) => (
                    <div key={valueIndex}>
                      {renderFeatureValue(value, valueIndex)}
                    </div>
                    // <div key={valueIndex} className="text-center text-white">
                    //   {value === "✓" ? (
                    //     <Check className="mx-auto text-green-400" size={20} />
                    //   ) : value === "×" ? (
                    //     <X className="mx-auto text-red-400" size={20} />
                    //   ) : (
                    //     <span>{value}</span>
                    //   )}
                    // </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* <div className="grid grid-cols-5 gap-4 mt-8">
          <div></div>
          <button
            onClick={() => toast.info("Feature coming soon!")}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Sign Up
          </button>
          <button
            onClick={() => toast.info("Feature coming soon!")}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Sign Up
          </button>
          <button
            onClick={() => toast.info("Feature coming soon!")}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Sign Up
          </button>
          <button
            onClick={() => toast.info("Feature coming soon!")}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Contact Us
          </button>
        </div>*/}
      </div>
    </div>
  );
};

export default PricingTable;
