import React, { useState } from "react";
import { Plus, X, Link as LinkIcon, Upload, FileText, AlertCircle, Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { urlService } from "../services/urlService";

const FloatingUrlButton = ({ onUrlCreated }) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showSingleUrlModal, setShowSingleUrlModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [bulkProgress, setBulkProgress] = useState(0);
  const [bulkResults, setBulkResults] = useState([]);
  const [csvPreview, setCsvPreview] = useState([]);
  const [formData, setFormData] = useState({
    longUrl: "",
    customAlias: "",
    customDomain: process.env.REACT_APP_BASE_URL || "https://shrtly.net",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await urlService.createShortUrl(formData);
      toast.success("URL shortened successfully!");
      setShowSingleUrlModal(false);
      setFormData({
        longUrl: "",
        customAlias: "",
        customDomain: process.env.REACT_APP_BASE_URL || "https://shrtly.net",
      });
      if (onUrlCreated) onUrlCreated();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const csvData = event.target.result;
        const rows = csvData.split('\n')
          .map(row => row.trim())
          .filter(row => row && row.length > 0);

        // Basic URL validation
        const validUrls = rows.map(url => {
          try {
            new URL(url); // This will throw an error if URL is invalid
            return { url, isValid: true };
          } catch {
            return { url, isValid: false };
          }
        });

        setCsvPreview(validUrls);
        setBulkResults([]);
      };
      reader.readAsText(file);
    } catch (error) {
      toast.error("Error reading CSV file");
    }
  };

  const handleBulkUpload = async () => {
    if (csvPreview.length === 0) {
      toast.error("No URLs to process");
      return;
    }

    setBulkLoading(true);
    setBulkProgress(0);
    setBulkResults([]);

    try {
      const validUrls = csvPreview.filter(item => item.isValid);
      const totalUrls = validUrls.length;
      const results = [];

      for (let i = 0; i < totalUrls; i++) {
        const { url } = validUrls[i];
        try {
          const result = await urlService.createShortUrl({
            longUrl: url,
            customDomain: process.env.REACT_APP_BASE_URL || "https://shrtly.net",
          });
          results.push({ url, success: true, result });
        } catch (error) {
          results.push({ url, success: false, error: error.message });
        }

        setBulkProgress(Math.round((i + 1) / totalUrls * 100));
      }

      setBulkResults(results);
      const successCount = results.filter(r => r.success).length;
      toast.success(`Successfully shortened ${successCount} out of ${totalUrls} URLs`);
      if (onUrlCreated) onUrlCreated();
      setCsvPreview([]); // Clear preview after processing
    } catch (error) {
      toast.error("Error processing URLs");
    } finally {
      setBulkLoading(false);
    }
  };

  const resetBulkUpload = () => {
    setCsvPreview([]);
    setBulkResults([]);
    setBulkProgress(0);
  };

  const handleRemoveUrl = (indexToRemove) => {
    setCsvPreview(prevUrls => prevUrls.filter((_, index) => index !== indexToRemove));
  };

  return (
    <>
      {/* Floating Action Button */}
      <button
        onClick={() => setShowOptionsModal(true)}
        className="fixed right-8 bottom-8 w-14 h-14 bg-cyan-600 hover:bg-cyan-700 rounded-full shadow-lg flex items-center justify-center text-white transition-colors z-20"
      >
        <Plus size={24} />
      </button>

      {/* Options Modal */}
      {showOptionsModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-30">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b">
              <div className="flex items-center gap-2">
                <LinkIcon className="text-cyan-600" size={20} />
                <h3 className="text-lg font-semibold text-gray-900">
                  Shorten URLs
                </h3>
              </div>
              <button
                onClick={() => setShowOptionsModal(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {/* Options */}
            <div className="p-4 grid grid-cols-2 gap-4">
              <button
                onClick={() => {
                  setShowOptionsModal(false);
                  setShowSingleUrlModal(true);
                }}
                className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-lg hover:border-cyan-500 hover:bg-cyan-50 transition-colors"
              >
                <FileText className="text-cyan-600 mb-2" size={24} />
                <span className="text-sm font-medium text-gray-700">Single URL</span>
              </button>
              <button
                onClick={() => {
                  setShowOptionsModal(false);
                  setShowBulkModal(true);
                }}
                className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-lg hover:border-cyan-500 hover:bg-cyan-50 transition-colors"
              >
                <Upload className="text-cyan-600 mb-2" size={24} />
                <span className="text-sm font-medium text-gray-700">Bulk Upload</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Single URL Modal */}
      {showSingleUrlModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-30">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b">
              <div className="flex items-center gap-2">
                <LinkIcon className="text-cyan-600" size={20} />
                <h3 className="text-lg font-semibold text-gray-900">
                  Shorten New URL
                </h3>
              </div>
              <button
                onClick={() => setShowSingleUrlModal(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Long URL
                </label>
                <input
                  type="url"
                  value={formData.longUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, longUrl: e.target.value })
                  }
                  placeholder="https://example.com"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Custom Alias (Optional)
                </label>
                <input
                  type="text"
                  value={formData.customAlias}
                  onChange={(e) =>
                    setFormData({ ...formData, customAlias: e.target.value })
                  }
                  placeholder="my-custom-url"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Domain
                </label>
                <select
                  value={formData.customDomain}
                  onChange={(e) =>
                    setFormData({ ...formData, customDomain: e.target.value })
                  }
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                >
                  <option value={process.env.REACT_APP_BASE_URL || "https://shrtly.net"}>
                    {new URL(process.env.REACT_APP_BASE_URL || "https://shrtly.net").hostname}
                  </option>
                </select>
              </div>

              <div className="flex items-center gap-4 pt-4">
                <button
                  type="button"
                  onClick={() => setShowSingleUrlModal(false)}
                  className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="flex-1 px-4 py-2 bg-cyan-600 text-white rounded-md hover:bg-cyan-700 transition-colors disabled:bg-cyan-300"
                >
                  {loading ? "Creating..." : "Create Short URL"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Bulk Upload Modal */}
      {showBulkModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-30">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b">
              <div className="flex items-center gap-2">
                <Upload className="text-cyan-600" size={20} />
                <h3 className="text-lg font-semibold text-gray-900">
                  Bulk Upload URLs
                </h3>
              </div>
              <button
                onClick={() => {
                  setShowBulkModal(false);
                  resetBulkUpload();
                }}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {/* Content */}
            <div className="p-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Upload CSV File
                </label>
                <p className="text-sm text-gray-500 mb-2">
                  Upload a CSV file containing one URL per line. The URLs should be valid and complete (including http:// or https://).
                </p>
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileSelect}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                  disabled={bulkLoading}
                />
              </div>

              {/* Preview Section */}
              {csvPreview.length > 0 && !bulkLoading && bulkResults.length === 0 && (
                <div className="mt-4">
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="text-sm font-medium text-gray-700">Preview ({csvPreview.length} URLs)</h4>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={resetBulkUpload}
                        className="px-3 py-1 text-sm border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors"
                      >
                        Clear
                      </button>
                      <button
                        onClick={handleBulkUpload}
                        className="px-3 py-1 text-sm bg-cyan-600 text-white rounded-md hover:bg-cyan-700 transition-colors"
                      >
                        Shorten All
                      </button>
                    </div>
                  </div>
                  <div className="max-h-60 overflow-y-auto border rounded-md">
                    {csvPreview.map((item, index) => (
                      <div
                        key={index}
                        className={`p-2 border-b last:border-b-0 flex items-center justify-between ${
                          item.isValid ? 'text-gray-700' : 'text-red-500'
                        }`}
                      >
                        <span className="truncate flex-1">{item.url}</span>
                        <div className="flex items-center gap-2">
                          {!item.isValid && (
                            <>
                              <div className="flex items-center gap-1 text-sm">
                                <AlertCircle size={16} />
                                <span>Invalid URL</span>
                              </div>
                              <button
                                onClick={() => handleRemoveUrl(index)}
                                className="p-1 hover:bg-red-50 rounded-md transition-colors"
                                title="Remove URL"
                              >
                                <Trash2 size={16} />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Progress Section */}
              {bulkLoading && (
                <div className="mb-4">
                  <div className="flex items-center justify-between mb-1">
                    <span className="text-sm font-medium text-gray-700">Processing...</span>
                    <span className="text-sm text-gray-500">{bulkProgress}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-cyan-600 h-2 rounded-full transition-all duration-300"
                      style={{ width: `${bulkProgress}%` }}
                    />
                  </div>
                </div>
              )}

              {/* Results Section */}
              {bulkResults.length > 0 && (
                <div className="mt-4">
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="text-sm font-medium text-gray-700">Results</h4>
                    <button
                      onClick={resetBulkUpload}
                      className="px-3 py-1 text-sm border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors"
                    >
                      Upload Another File
                    </button>
                  </div>
                  <div className="max-h-60 overflow-y-auto">
                    {bulkResults.map((result, index) => (
                      <div
                        key={index}
                        className={`p-2 rounded-md mb-1 text-sm ${
                          result.success
                            ? 'bg-green-50 text-green-700'
                            : 'bg-red-50 text-red-700'
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <span className="truncate">{result.url}</span>
                          <div className="flex items-center gap-2">
                            {result.success && (
                              <span className="text-xs bg-green-100 px-2 py-0.5 rounded">
                                {result.result.shortCode}
                              </span>
                            )}
                            <span className="ml-2">{result.success ? '✓' : '✗'}</span>
                          </div>
                        </div>
                        {!result.success && (
                          <p className="text-xs mt-1 text-red-600">{result.error}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

FloatingUrlButton.propTypes = {
  onUrlCreated: PropTypes.func,
};

FloatingUrlButton.defaultProps = {
  onUrlCreated: () => {},
};

export default FloatingUrlButton;
