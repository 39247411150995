import React, { useEffect, useState, useMemo } from "react";
import {
  QrCode,
  Share2,
  Copy,
  Trash2,
  Search,
  Pencil,
  Replace,
  Save,
  Ban,
  Eye,
  Link2,
} from "lucide-react";
import { timeAgo } from "../helpers/datetime/timeAgo";
import { toast } from "react-toastify";
import { urlService } from "../services/urlService";
import ShareDropdown from "./helpers/ShareDropdown";
import { useNavigate } from "react-router-dom";
import { TagBadge, TagInput } from "./TagBadge";
import QRCodeModal from "./QRCodeModal";
import TagFilter from "./TagFilter";
import FloatingUrlButton from "./FloatingUrlButton";

const UrlList = () => {
  const navigate = useNavigate();
  const [urls, setUrls] = useState([]);
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editType, setEditType] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [shareOpen, setShareOpen] = useState(null);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [sortOption, setSortOption] = useState("dateDesc");
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [showUrlForm, setShowUrlForm] = useState(false);
  const [newUrlData, setNewUrlData] = useState({
    longUrl: "",
    customAlias: "",
    customDomain: "https://shrtly.net",
  });

  // Get all unique existing tags
  const existingTags = useMemo(() => {
    const tagSet = new Set();
    urls.forEach(url => {
      url.tags?.forEach(tag => {
        tagSet.add(tag.name);
      });
    });
    return Array.from(tagSet);
  }, [urls]);

  useEffect(() => {
    fetchUrls();
  }, []);

  // Filter and sort URLs
  useEffect(() => {
    let filtered = [...urls];

    // Apply tag filtering
    if (selectedTags.length > 0) {
      filtered = filtered.filter((url) =>
        selectedTags.every((selectedTag) =>
          url.tags?.some((urlTag) => urlTag.name === selectedTag.name)
        )
      );
    }

    // Apply search query filtering
    if (searchQuery) {
      filtered = filtered.filter(
        (url) =>
          url.originalUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
          url.shortCode.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case "shortCode":
          comparison = a.shortCode.localeCompare(b.shortCode);
          break;
        case "originalUrl":
          comparison = a.originalUrl.localeCompare(b.originalUrl);
          break;
        case "totalClicks":
          comparison = a.totalClicks - b.totalClicks;
          break;
        case "createdAt":
          comparison = new Date(a.createdAt) - new Date(b.createdAt);
          break;
        default:
          comparison = 0;
      }
      return sortDirection === "asc" ? comparison : -comparison;
    });

    setFilteredUrls(filtered);
  }, [urls, selectedTags, searchQuery, sortField, sortDirection]);

  const handleCreateUrl = async (e) => {
    e.preventDefault();
    try {
      const response = await urlService.createShortUrl(newUrlData);
      toast.success("URL shortened successfully!");
      setShowUrlForm(false);
      setNewUrlData({
        longUrl: "",
        customAlias: "",
        customDomain: "https://shrtly.net",
      });
      fetchUrls(); // Refresh the list
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTagFilter = (tags) => {
    setSelectedTags(tags);
  };

  const fetchUrls = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await urlService.getAllUrls();
      setUrls(data);
      setFilteredUrls(data);
    } catch (err) {
      const errorMessage = err.message || "An error occurred while fetching URLs";
      setError(errorMessage);
      
      // Check if it's a rate limit error
      if (errorMessage.toLowerCase().includes("too many requests")) {
        toast.error(
          <div className="flex flex-col gap-2">
            <p>Rate limit exceeded. Please wait a moment before trying again.</p>
            <p className="text-sm text-white/60">This usually happens when making too many requests in a short time.</p>
          </div>,
          {
            autoClose: 5000,
            position: "top-center",
          }
        );
      } else {
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  // Add retry mechanism
  const handleRetry = async () => {
    // Wait for 2 seconds before retrying
    await new Promise(resolve => setTimeout(resolve, 2000));
    fetchUrls();
  };

  const handleQrCodeClick = (url) => {
    setSelectedUrl(`${url.customDomain}/${url.shortCode}`);
    setQrCodeModalOpen(true);
  };

  const deleteUrl = async (id) => {
    try {
      setLoading(true);
      await urlService.deleteUrl(id);
      await fetchUrls();
      toast.success("URL deleted successfully!");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    toast.success("URL copied successfully!");
  };

  const startEditing = (url, type) => {
    setEditingId(url._id);
    setEditType(type);
    setEditValue(type === "shortCode" ? url.shortCode : url.originalUrl);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditType(null);
    setEditValue("");
  };

  const handleUpdate = async (id) => {
    try {
      setLoading(true);
      const updateData =
        editType === "shortCode"
          ? { shortCode: editValue }
          : { originalUrl: editValue };

      await urlService.updateUrl(id, updateData);
      await fetchUrls();
      cancelEditing();
      toast.success(`URL ${editType} updated successfully!`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin h-12 w-12 border-4 border-blue-500 border-t-transparent rounded-full" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8 text-center">
        <div className="max-w-2xl mx-auto bg-red-500/10 p-6 rounded-lg border border-red-500/20">
          <p className="text-red-400 mb-4">{error}</p>
          <button
            onClick={handleRetry}
            className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors flex items-center gap-2 mx-auto"
          >
            <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
      <div className="max-w-[1600px] mx-auto space-y-6">
        <div className="flex flex-col md:flex-row justify-between items-start gap-4">
          <h1 className="text-3xl font-bold text-white">Your URLs</h1>
          <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
            <div className="w-full md:w-96">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search URLs..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-white/5 text-white rounded-full py-3 px-6 pl-12 border border-white/10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Search
                  className="absolute left-4 top-3.5 text-white/60"
                  size={20}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Tag Filter */}
        <TagFilter
          onFilterChange={handleTagFilter}
          className="bg-white/5 p-4 rounded-lg"
        />

        {/* URL Table */}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left text-white/60 text-sm border-b border-white/10">
                <th 
                  className="pb-4 cursor-pointer hover:text-white transition-colors"
                  onClick={() => handleSort("shortCode")}
                >
                  <div className="flex items-center gap-2">
                    Short URL
                    {sortField === "shortCode" && (
                      <span className="text-white">
                        {sortDirection === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </th>
                <th 
                  className="pb-4 cursor-pointer hover:text-white transition-colors"
                  onClick={() => handleSort("originalUrl")}
                >
                  <div className="flex items-center gap-2">
                    Original URL
                    {sortField === "originalUrl" && (
                      <span className="text-white">
                        {sortDirection === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </th>
                <th className="pb-4">Tags</th>
                <th 
                  className="pb-4 cursor-pointer hover:text-white transition-colors"
                  onClick={() => handleSort("totalClicks")}
                >
                  <div className="flex items-center gap-2">
                    Clicks
                    {sortField === "totalClicks" && (
                      <span className="text-white">
                        {sortDirection === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </th>
                <th 
                  className="pb-4 cursor-pointer hover:text-white transition-colors"
                  onClick={() => handleSort("createdAt")}
                >
                  <div className="flex items-center gap-2">
                    Created
                    {sortField === "createdAt" && (
                      <span className="text-white">
                        {sortDirection === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </th>
                <th className="pb-4">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-white/10">
              {filteredUrls.map((url) => (
                <tr key={url._id} className="hover:bg-white/5 transition-colors">
                  <td className="py-4">
                    <div className="flex items-center gap-2">
                      <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm font-bold">
                        {url.customDomain.charAt(0).toUpperCase()}
                      </div>
                      <span className="text-white">
                        {editingId === url._id && editType === "shortCode" ? (
                          <div className="flex items-center gap-2">
                            <input
                              type="text"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              className="bg-black/20 text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                              autoFocus
                            />
                            <button
                              onClick={() => handleUpdate(url._id)}
                              className="p-1 text-green-400 hover:text-green-300"
                              title="Save"
                            >
                              <Save size={16} />
                            </button>
                            <button
                              onClick={cancelEditing}
                              className="p-1 text-red-400 hover:text-red-300"
                              title="Cancel"
                            >
                              <Ban size={16} />
                            </button>
                          </div>
                        ) : (
                          `${url.customDomain}/${url.shortCode}`
                        )}
                      </span>
                    </div>
                  </td>
                  <td className="py-4">
                    <div className="flex items-center gap-2 group relative">
                      <button
                        onClick={() => copyUrl(url.originalUrl)}
                        className="p-1.5 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                        title="Copy original URL"
                      >
                        <Copy size={14} />
                      </button>
                      <span className="text-white/60 truncate max-w-[300px]">
                        {editingId === url._id && editType === "originalUrl" ? (
                          <div className="flex items-center gap-2">
                            <input
                              type="text"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              className="bg-black/20 text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                              autoFocus
                            />
                            <button
                              onClick={() => handleUpdate(url._id)}
                              className="p-1 text-green-400 hover:text-green-300"
                              title="Save"
                            >
                              <Save size={16} />
                            </button>
                            <button
                              onClick={cancelEditing}
                              className="p-1 text-red-400 hover:text-red-300"
                              title="Cancel"
                            >
                              <Ban size={16} />
                            </button>
                          </div>
                        ) : (
                          url.originalUrl
                        )}
                      </span>
                      <div className="absolute left-0 top-full mt-2 px-3 py-2 bg-gray-900/95 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10 max-w-[500px] break-all">
                        {url.originalUrl}
                      </div>
                    </div>
                  </td>
                  <td className="py-4">
                    <div className="flex flex-wrap gap-2">
                      {url.tags?.map((tag) => (
                        <TagBadge
                          key={tag.name}
                          tag={tag}
                          onRemove={async () => {
                            try {
                              await urlService.removeTag(url._id, tag.name);
                              fetchUrls();
                            } catch (error) {
                              toast.error(error.message);
                            }
                          }}
                        />
                      ))}
                      <TagInput
                        existingTags={existingTags}
                        currentTags={url.tags || []}
                        onAddTag={async (newTag) => {
                          try {
                            await urlService.addTags(url._id, [newTag]);
                            fetchUrls();
                          } catch (error) {
                            toast.error(error.message);
                          }
                        }}
                        onRemoveTag={async (tag) => {
                          try {
                            await urlService.removeTag(url._id, tag.name);
                            fetchUrls();
                          } catch (error) {
                            toast.error(error.message);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className="py-4">
                    <span className="text-white/60">{url.totalClicks}</span>
                  </td>
                  <td className="py-4">
                    <span className="text-white/60">{timeAgo(url.createdAt)}</span>
                  </td>
                  <td className="py-4">
                    <div className="flex flex-wrap gap-2">
                      <button
                        disabled
                        onClick={() => startEditing(url, "shortCode")}
                        className="p-2 rounded-lg bg-white/10 cursor-not-allowed opacity-50 text-white transition-colors group relative"
                        title="Edit Short Code"
                      >
                        <Pencil size={18} />
                        <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 px-3 py-1 bg-gray-900/75 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                          Available in Premium Plan
                        </div>
                      </button>

                      <button
                        disabled
                        onClick={() => startEditing(url, "originalUrl")}
                        className="p-2 rounded-lg bg-white/10 cursor-not-allowed opacity-50 text-white transition-colors group relative"
                        title="Edit Original URL"
                      >
                        <Replace size={18} />
                        <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 px-3 py-1 bg-gray-900/75 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                          Available in Premium Plan
                        </div>
                      </button>
                      <button
                        onClick={() => handleQrCodeClick(url)}
                        className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                        title="Generate QR Code"
                      >
                        <QrCode size={18} />
                      </button>
                      <ShareDropdown
                        url={url}
                        isOpen={shareOpen === url._id}
                        setIsOpen={(open) => setShareOpen(open ? url._id : null)}
                      />
                      <button
                        onClick={() => copyUrl(`${url.customDomain}/${url.shortCode}`)}
                        className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                        title="Copy URL"
                      >
                        <Copy size={18} />
                      </button>
                      <button
                        disabled
                        onClick={() => deleteUrl(url._id)}
                        className="p-2 rounded-lg bg-white/10 cursor-not-allowed opacity-50 text-white transition-colors group relative"
                        title="Delete"
                      >
                        <Trash2 size={18} />
                        <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 px-3 py-1 bg-gray-900/75 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                          Available in Premium Plan
                        </div>
                      </button>
                      <button
                        onClick={() => navigate(`/details/${url.shortCode}`)}
                        className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                        title="View Analytics"
                      >
                        <Eye size={18} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredUrls.length === 0 && (
          <div className="text-center py-12">
            <p className="text-white/60">No URLs found</p>
          </div>
        )}
      </div>

      {/* QR Code Modal */}
      <QRCodeModal
        isOpen={qrCodeModalOpen}
        onClose={() => setQrCodeModalOpen(false)}
        url={selectedUrl}
      />

      {/* Floating Action Button */}
      <FloatingUrlButton onUrlCreated={fetchUrls} />
    </div>
  );
};

export default UrlList;
