import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut, User, Settings, CreditCard, ChevronDown } from "lucide-react";
import { useAuth } from "./context/AuthContext";

const Navbar = () => {
  const { isAuthenticated, logout, userInfo, setCloseDropdown } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Set the closeDropdown callback when dropdown state changes
    setCloseDropdown(() => () => setIsDropdownOpen(false));
  }, [setCloseDropdown]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <nav className="bg-transparent p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <button
          className="text-white text-3xl font-bold tracking-wider"
          onClick={() => navigate("/")}
        >
          SHRTLY
        </button>
        <div className="flex items-center gap-6">
          {isAuthenticated ? (
            <>
              <button
                onClick={() => navigate("/urls")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                My URLs
              </button>
              <button
                onClick={() => navigate("/plans")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Plans
              </button>
              <button
                onClick={() => navigate("/features")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Features
              </button>
              {/* User Profile Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center gap-2 text-white hover:text-teal-200 transition-colors"
                >
                  <User size={20} className="text-teal-300" />
                  <span className="text-teal-100">{userInfo?.email}</span>
                  <ChevronDown
                    size={16}
                    className={`transition-transform ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                    <button
                      onClick={() => {
                        navigate("/profile");
                        setIsDropdownOpen(false);
                      }}
                      className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                    >
                      <Settings size={16} />
                      Profile Settings
                    </button>
                    <button
                      onClick={() => {
                        navigate("/my-plan");
                        setIsDropdownOpen(false);
                      }}
                      className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                    >
                      <CreditCard size={16} />
                      My Plan
                    </button>
                    <hr className="my-1" />
                    <button
                      onClick={handleLogout}
                      className="flex items-center gap-2 px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                    >
                      <LogOut size={16} />
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <button
                onClick={() => navigate("/features")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Features
              </button>
              <button
                onClick={() => navigate("/plans")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Plans
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition-colors"
              >
                Sign Up
              </button>
              <button
                onClick={() => navigate("/signin")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Sign In
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
