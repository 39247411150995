import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Copy,
  ExternalLink,
  Globe,
  Calendar,
  MousePointerClick,
} from "lucide-react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { timeAgo } from "../helpers/datetime/timeAgo";
import { urlService } from "../services/urlService";

const UrlDetails = () => {
  const { shortCode } = useParams();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const groupBrowserData = (browserData) => {
    const browserMapping = {
      Chrome: /Chrome|chrome/i,
      Firefox: /Firefox|firefox/i,
      Safari: /Safari|safari/i,
      Edge: /Edge|edge/i,
      "Mobile Chrome": /Mobile Chrome|mobile chrome/i,
      "Mobile Safari": /Mobile Safari|mobile safari/i,
      "Mobile Firefox": /Mobile Firefox|mobile firefox/i,
    };

    const groupedData = {};

    Object.entries(browserData).forEach(([browser, count]) => {
      let matched = false;
      for (const [groupName, pattern] of Object.entries(browserMapping)) {
        if (pattern.test(browser)) {
          groupedData[groupName] = (groupedData[groupName] || 0) + count;
          matched = true;
          break;
        }
      }
      if (!matched) {
        groupedData["Other"] = (groupedData["Other"] || 0) + count;
      }
    });

    return Object.entries(groupedData).map(([browser, count]) => ({
      name: browser,
      clicks: count,
    }));
  };

  const groupOSData = (osData) => {
    const osMapping = {
      Windows: /Windows/i,
      macOS: /macOS/i,
      iOS: /iOS/i,
      Android: /Android/i,
      Linux: /Linux/i,
    };

    const groupedData = {};

    Object.entries(osData).forEach(([os, count]) => {
      let matched = false;
      for (const [groupName, pattern] of Object.entries(osMapping)) {
        if (pattern.test(os)) {
          groupedData[groupName] = (groupedData[groupName] || 0) + count;
          matched = true;
          break;
        }
      }
      if (!matched) {
        groupedData["Other"] = (groupedData["Other"] || 0) + count;
      }
    });

    return Object.entries(groupedData).map(([os, count]) => ({
      name: os,
      clicks: count,
    }));
  };

  const prepareReferrerData = (referrerData) => {
    // Group referrers into categories
    const categories = {
      "Social Media": [
        "Facebook",
        "Twitter",
        "LinkedIn",
        "Instagram",
        "Pinterest",
        "Reddit",
      ],
      "Search Engines": ["Google", "Bing"],
      "Video Platforms": ["YouTube"],
      Email: ["Email"],
      Other: ["Other"],
      Direct: ["Direct"],
    };

    // Initialize grouped data
    const groupedData = {};

    // Initialize all categories with 0 to ensure they appear in the chart
    Object.keys(categories).forEach((category) => {
      groupedData[category] = 0;
    });

    // Sum up clicks for each referrer into their respective categories
    Object.entries(referrerData).forEach(([source, count]) => {
      let categoryFound = false;

      // Check each category to find where this source belongs
      for (const [category, sources] of Object.entries(categories)) {
        if (sources.includes(source)) {
          groupedData[category] = (groupedData[category] || 0) + count;
          categoryFound = true;
          break;
        }
      }

      // If source doesn't match any predefined category, add to Other
      if (!categoryFound && source !== "Direct") {
        groupedData["Other"] = (groupedData["Other"] || 0) + count;
      }
    });

    // Convert to array format needed for the chart
    // Filter out categories with 0 clicks
    return Object.entries(groupedData)
      .filter(([_, value]) => value > 0)
      .map(([category, clicks]) => ({
        name: category,
        clicks: clicks,
      }));
  };

  const groupReferrerData = (referrerData) => {
    const referrerMapping = {
      Direct: /(direct|none|unknown)/i,
      "Social Media": /(social)/i,
      Instagram: /(instagram)/i,
      Facebook: /(facebook)/i,
      Twitter: /(twitter|x)/i,
      "Search Engines": /(google|bing|yahoo|search)/i,
      Email: /(mail|outlook|gmail)/i,
      "Other Sites": /.*/,
    };

    const groupedData = {};

    Object.entries(referrerData).forEach(([referrer, count]) => {
      let matched = false;
      for (const [groupName, pattern] of Object.entries(referrerMapping)) {
        if (pattern.test(referrer)) {
          groupedData[groupName] = (groupedData[groupName] || 0) + count;
          matched = true;
          break;
        }
      }
      if (!matched) {
        groupedData["Other"] = (groupedData["Other"] || 0) + count;
      }
    });

    return Object.entries(groupedData).map(([referrer, count]) => ({
      name: referrer,
      clicks: count,
    }));
  };

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const data = await urlService.getUrlAnalytics(shortCode);
        setAnalytics(data);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (shortCode) {
      fetchAnalytics();
    }
  }, [shortCode]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-6">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cyan-600"></div>
          <p className="ml-4 text-white/60">Loading analytics...</p>
        </div>
      </div>
    );
  }

  if (error || !analytics) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-6">
        <div className="text-center text-red-400">
          <p>{error || "No analytics data available"}</p>
        </div>
      </div>
    );
  }

  // Prepare grouped data for charts
  const browserData = groupBrowserData(analytics.clicksByBrowser);
  const osData = groupOSData(analytics.clicksByOS);
  const referrerData = groupReferrerData(analytics.clicksByReferrer || {});
  const countryData = Object.entries(analytics.clicksByCountry).map(
    ([country, count]) => ({
      name: country || "Unknown",
      clicks: count,
    })
  );

  const CHART_COLORS = {
    browser: ["#0891b2", "#0ea5e9", "#38bdf8", "#7dd3fc", "#bae6fd"],
    os: ["#059669", "#10b981", "#34d399", "#6ee7b7", "#a7f3d0"],
    referrer: [
      "#4f46e5", // Social Media
      "#7c3aed", // Search Engines
      "#a855f7", // Video Platforms
      "#ec4899", // Email
      "#f43f5e", // Other
      "#64748b", // Direct
    ],
    country: ["#d97706", "#f59e0b", "#fbbf24", "#fcd34d", "#fde68a"],
  };

  const renderPieChart = (data, title, colorSet) => (
    <div className="bg-white/5 backdrop-blur-sm rounded-lg shadow-xl p-3 border border-white/10">
      <h2 className="text-base font-semibold text-white mb-3">{title}</h2>
      <div className="h-48">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="clicks"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={55}
              paddingAngle={5}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colorSet[index % colorSet.length]}
                />
              ))}
            </Pie>
            <Tooltip 
              contentStyle={{ 
                backgroundColor: '#1f2937', 
                border: 'none', 
                borderRadius: '0.375rem',
                fontSize: '0.75rem'
              }} 
            />
            <Legend 
              formatter={(value) => <span className="text-white/80 text-xs">{value}</span>}
              wrapperStyle={{ fontSize: '0.75rem' }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
      <div className="w-full max-w-[1600px] mx-auto p-4">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-2xl font-bold text-white">
              Analytics Overview
            </h1>
            <p className="text-sm text-white/60">
              Track and analyze your link performance
            </p>
          </div>
        </div>

        {/* URL Cards */}
        <div className="grid grid-cols-1 gap-4 mb-4">
          <div className="bg-white/5 backdrop-blur-sm rounded-lg shadow-xl p-4 border border-white/10">
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <label className="text-sm text-white/60">Short URL</label>
                <div className="flex items-center gap-2 mt-1">
                  <a
                    href={analytics.urlDetails.fullShortUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-cyan-400 hover:text-cyan-300 text-sm"
                  >
                    {analytics.urlDetails.fullShortUrl}
                  </a>
                  <button
                    onClick={() =>
                      copyToClipboard(analytics.urlDetails.fullShortUrl)
                    }
                    className="p-1 rounded-md hover:bg-white/10 text-white/60"
                    title="Copy URL"
                  >
                    <Copy size={14} />
                  </button>
                  <a
                    href={analytics.urlDetails.fullShortUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-1 rounded-md hover:bg-white/10 text-white/60"
                  >
                    <ExternalLink size={14} />
                  </a>
                </div>
              </div>
              <div className="text-right">
                <label className="text-sm text-white/60">Original URL</label>
                <div className="flex items-center gap-2 mt-1 justify-end">
                  <span className="text-white/80 truncate max-w-md text-sm">
                    {analytics.urlDetails.originalUrl}
                  </span>
                  <button
                    onClick={() =>
                      copyToClipboard(analytics.urlDetails.originalUrl)
                    }
                    className="p-1 rounded-md hover:bg-white/10 text-white/60"
                  >
                    <Copy size={14} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <div className="bg-white/5 backdrop-blur-sm rounded-lg shadow-xl p-3 border border-white/10">
            <div className="flex items-center">
              <MousePointerClick className="w-8 h-8 text-cyan-400" />
              <div className="ml-3">
                <p className="text-xs text-white/60">Total Clicks</p>
                <p className="text-lg font-semibold text-white">
                  {analytics.totalClicks.toLocaleString()}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white/5 backdrop-blur-sm rounded-lg shadow-xl p-3 border border-white/10">
            <div className="flex items-center">
              <Calendar className="w-8 h-8 text-cyan-400" />
              <div className="ml-3">
                <p className="text-xs text-white/60">Created</p>
                <p className="text-lg font-semibold text-white">
                  {timeAgo(analytics.urlDetails.createdAt)}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white/5 backdrop-blur-sm rounded-lg shadow-xl p-3 border border-white/10">
            <div className="flex items-center">
              <Globe className="w-8 h-8 text-cyan-400" />
              <div className="ml-3">
                <p className="text-xs text-white/60">Custom Domain</p>
                <p className="text-lg font-semibold text-white">
                  {analytics.urlDetails.customDomain}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Charts Row Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {renderPieChart(browserData, "Browsers", CHART_COLORS.browser)}
          {renderPieChart(osData, "Operating Systems", CHART_COLORS.os)}
          {renderPieChart(
            referrerData,
            "Traffic Sources",
            CHART_COLORS.referrer
          )}
        </div>
      </div>
    </div>
  );
};

export default UrlDetails;
