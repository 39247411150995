import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { X, Download } from "lucide-react";

const QRCodeModal = ({ isOpen, onClose, url }) => {
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    if (isOpen && url) {
      // Use QR Server API but with higher error correction
      const size = 256;
      const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${encodeURIComponent(
        url
      )}&ecc=H&margin=1`;
      setQrCode(qrCodeUrl);
    }
  }, [isOpen, url]);

  // Get filename from URL
  const getFilename = () => {
    // Extract the last part of the URL (after the last slash)
    const urlParts = url.split("/");
    const shortCode = urlParts[urlParts.length - 1];
    return `shrtly-${shortCode}`;
  };

  const handleDownloadPNG = async () => {
    try {
      // Fetch the image
      const response = await fetch(qrCode);
      const blob = await response.blob();

      // Create a canvas to modify the image
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        // Set canvas size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw QR code
        ctx.drawImage(img, 0, 0);

        // Add branding text
        ctx.fillStyle = "white";
        ctx.fillRect(canvas.width / 2 - 60, canvas.height / 2 - 15, 120, 30);
        ctx.strokeStyle = "#e5e7eb";
        ctx.strokeRect(canvas.width / 2 - 60, canvas.height / 2 - 15, 120, 30);

        ctx.font = "16px sans-serif";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText("shrtly.net", canvas.width / 2, canvas.height / 2);

        // Create download link
        const link = document.createElement("a");
        link.download = `${getFilename()}.png`;
        link.href = canvas.toDataURL("image/png");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      img.src = URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error downloading QR code as PNG:", error);
    }
  };

  const handleDownloadSVG = async () => {
    try {
      // Fetch QR code SVG
      const qrResponse = await fetch(
        `https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${encodeURIComponent(
          url
        )}&ecc=H&margin=1&format=svg`
      );
      const qrSvgText = await qrResponse.text();

      // Parse the QR code SVG
      const parser = new DOMParser();
      const qrDoc = parser.parseFromString(qrSvgText, "image/svg+xml");

      // Get the viewBox from original SVG
      const originalSvg = qrDoc.querySelector("svg");
      const viewBox = originalSvg.getAttribute("viewBox") || "0 0 256 256";

      // Create the new SVG document
      const svgContent = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}">
          <style>
            .brand-bg { fill: white; stroke: #e5e7eb; }
            .brand-text { font-family: sans-serif; font-size: 16px; }
          </style>
          <g id="qr-content">
            ${originalSvg.innerHTML}
          </g>
          <g id="branding">
            <rect x="78" y="113" width="100" height="30" class="brand-bg"/>
            <text x="128" y="132" text-anchor="middle" class="brand-text">shrtly.net</text>
          </g>
        </svg>
      `;

      // Create blob and trigger download
      const blob = new Blob([svgContent], { type: "image/svg+xml" });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${getFilename()}.svg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading QR code as SVG:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-lg font-semibold text-gray-900">QR Code</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="p-4">
          <div className="bg-gray-50 p-4 rounded-lg flex items-center justify-center">
            <div className="relative w-64 h-64">
              {qrCode && (
                <>
                  <img src={qrCode} alt="QR Code" className="w-full h-full" />
                  <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="bg-white px-6 py-2 rounded border border-gray-200">
                      <span className="text-gray-800 font-medium text-sm">
                        shrtly.net
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="mt-4 text-sm text-gray-500 text-center">
            <p>Scan this QR code to access:</p>
            <p className="font-medium text-gray-700 truncate">{url}</p>
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 border-t">
          <div className="grid grid-cols-2 gap-2">
            <button
              onClick={handleDownloadPNG}
              className="flex items-center justify-center gap-2 bg-cyan-600 text-white py-2 px-4 rounded-md hover:bg-cyan-700 transition-colors"
            >
              <Download size={18} />
              Download as PNG
            </button>
            <button
              onClick={handleDownloadSVG}
              className="flex items-center justify-center gap-2 bg-white text-cyan-600 border border-cyan-600 py-2 px-4 rounded-md hover:bg-cyan-50 transition-colors"
            >
              <Download size={18} />
              Download as SVG
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

QRCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default QRCodeModal;
