import React, { useState, useEffect } from "react";
import {
  User,
  Mail,
  Shield,
  ExternalLink,
  Eye,
  Key,
  Loader2,
  Package,
  Link2,
  BarChart2,
} from "lucide-react";
import { useAuth } from "./context/AuthContext";
import { toast } from "react-toastify";
import { urlService } from "../services/urlService";

const Profile = () => {
  const { userInfo } = useAuth();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    fetchPlanInfo();
  }, []);

  const fetchPlanInfo = async () => {
    try {
      const response = await urlService.getPlanUsage();
      setPlanInfo(response);
    } catch (error) {
      toast.error("Failed to fetch plan information");
    }
  };

  const isGoogleUser = userInfo?.authType === "google";

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      toast.error("New passwords don't match!");
      return;
    }

    if (passwordForm.newPassword.length < 6) {
      toast.error("New password must be at least 6 characters long");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentPassword: passwordForm.currentPassword,
            newPassword: passwordForm.newPassword,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to change password");
      }

      toast.success("Password changed successfully!");
      setPasswordForm({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
      <div className="max-w-6xl mx-auto">
        {/* Profile Header Card */}
        <div className="bg-white/5 rounded-xl p-8 mb-6 border border-white/10">
          <div className="flex items-center gap-6">
            <div className="w-24 h-24 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full flex items-center justify-center">
              <User size={48} className="text-white" />
            </div>
            <div>
              <h1 className="text-3xl font-bold text-white mb-2">
                Profile Settings
              </h1>
              <p className="text-white/60 text-lg">
                Manage your account preferences and settings
              </p>
            </div>
          </div>
        </div>

        {/* Profile Details */}
        <div className="grid gap-6">
          {/* Email Section */}
          <div className="bg-white/5 rounded-xl p-8 border border-white/10">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                <Mail className="text-blue-400" size={24} />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  Email Address
                </h2>
                <p className="text-white/60">
                  Your primary email for communications
                </p>
              </div>
            </div>

            <div className="bg-white/5 rounded-lg p-4 flex items-center justify-between">
              <span className="text-white font-medium">{userInfo?.email}</span>
              {isGoogleUser && (
                <div className="flex items-center gap-2 text-white/60">
                  <img
                    src="/google-icon.svg"
                    alt="Google"
                    className="w-5 h-5"
                  />
                  <span>Google Account</span>
                  <ExternalLink size={16} />
                </div>
              )}
            </div>

            {isGoogleUser && (
              <div className="mt-4 flex items-start gap-3 p-4 bg-blue-500/10 rounded-lg">
                <Shield size={20} className="text-blue-400 mt-1" />
                <div>
                  <p className="text-white/80">
                    This account uses Google for authentication. Password and
                    email management are handled through your Google Account
                    settings.
                  </p>
                  <a
                    href="https://myaccount.google.com/security"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-1 text-blue-400 hover:text-blue-300 mt-2"
                  >
                    Manage Google Account Settings
                    <ExternalLink size={14} />
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Password Section - Only for email users */}
          {!isGoogleUser && (
            <div className="bg-white/5 rounded-xl p-8 border border-white/10">
              <div className="flex items-center gap-4 mb-6">
                <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                  <Key className="text-blue-400" size={24} />
                </div>
                <div>
                  <h2 className="text-xl font-semibold text-white">
                    Change Password
                  </h2>
                  <p className="text-white/60">Update your account password</p>
                </div>
              </div>

              <form onSubmit={handlePasswordChange} className="space-y-4">
                {/* Current Password */}
                <div>
                  <label className="block text-white/80 mb-2">
                    Current Password
                  </label>
                  <div className="relative">
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      value={passwordForm.currentPassword}
                      onChange={(e) =>
                        setPasswordForm({
                          ...passwordForm,
                          currentPassword: e.target.value,
                        })
                      }
                      className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-white/40 hover:text-white/60"
                    >
                      <Eye size={20} />
                    </button>
                  </div>
                </div>

                {/* New Password */}
                <div>
                  <label className="block text-white/80 mb-2">
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      value={passwordForm.newPassword}
                      onChange={(e) =>
                        setPasswordForm({
                          ...passwordForm,
                          newPassword: e.target.value,
                        })
                      }
                      className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                      minLength={6}
                    />
                    <button
                      type="button"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-white/40 hover:text-white/60"
                    >
                      <Eye size={20} />
                    </button>
                  </div>
                </div>

                {/* Confirm New Password */}
                <div>
                  <label className="block text-white/80 mb-2">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    value={passwordForm.confirmPassword}
                    onChange={(e) =>
                      setPasswordForm({
                        ...passwordForm,
                        confirmPassword: e.target.value,
                      })
                    }
                    className="w-full p-3 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 flex items-center justify-center disabled:bg-blue-500/50 transition-colors mt-6"
                >
                  {loading ? (
                    <Loader2 className="animate-spin" size={20} />
                  ) : (
                    "Update Password"
                  )}
                </button>
              </form>
            </div>
          )}

          {/* Account Security Section */}
          <div className="bg-white/5 rounded-xl p-8 border border-white/10">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center">
                <Shield className="text-blue-400" size={24} />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  Account Security
                </h2>
                <p className="text-white/60">
                  Manage your account&apos;s security settings
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between bg-white/5 p-4 rounded-lg border border-white/10">
                <div>
                  <h3 className="text-white font-medium">
                    Two-Factor Authentication
                  </h3>
                  <p className="text-white/60 text-sm">
                    Add an extra layer of security to your account
                  </p>
                </div>
                <button className="px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors">
                  Coming Soon
                </button>
              </div>

              <div className="flex items-center justify-between bg-white/5 p-4 rounded-lg border border-white/10">
                <div>
                  <h3 className="text-white font-medium">Login History</h3>
                  <p className="text-white/60 text-sm">
                    View your recent login activity
                  </p>
                </div>
                <button className="px-4 py-2 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
