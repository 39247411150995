import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import PropTypes from "prop-types";

const GoogleAuthButton = ({ onSuccess, onError }) => {
  return (
    <div className="w-full flex justify-center my-4">
      <GoogleLogin
        clientId="725009883467-u66h4cpao2iv5ldib2hv1oe6lj1k2pkj.apps.googleusercontent.com"
        onSuccess={onSuccess}
        onError={onError}
        useOneTap
        theme="filled_blue"
        size="large"
        width="280"
        text="continue_with"
      />
    </div>
  );
};

GoogleAuthButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default GoogleAuthButton;
