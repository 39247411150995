import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { X, Tag as TagIcon } from "lucide-react";
import { urlService } from "../services/urlService";

const TagFilter = ({ onFilterChange, className = "" }) => {
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        setIsLoading(true);
        const urls = await urlService.getAllUrls();
        const uniqueTags = [];
        urls.forEach((url) => {
          const urlTags = url.tags || [];
          urlTags.forEach((tag) => {
            if (!uniqueTags.find((t) => t.name === tag.name)) {
              uniqueTags.push({
                name: tag.name,
                color: tag.color || "#3B82F6",
              });
            }
          });
        });
        setAllTags(uniqueTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTags();
  }, []);

  const toggleTag = (tag) => {
    const newSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t.name !== tag.name)
      : [...selectedTags, tag];

    setSelectedTags(newSelectedTags);
    onFilterChange(newSelectedTags);
  };

  if (isLoading) {
    return (
      <div className="text-white/60 flex items-center gap-2">
        <TagIcon size={16} />
        Loading tags...
      </div>
    );
  }

  if (allTags.length === 0) {
    return (
      <div className="text-white/60 flex items-center gap-2">
        <TagIcon size={16} />
        No tags available
      </div>
    );
  }

  return (
    <div className={`flex flex-wrap gap-2 items-center ${className}`}>
      <div className="flex items-center gap-2 text-white/60">
        <TagIcon size={16} />
        Filter by tags
      </div>
      {allTags.map((tag) => (
        <button
          key={tag.name}
          onClick={() => toggleTag(tag)}
          className={`inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-sm
            ${
              selectedTags.find((t) => t.name === tag.name)
                ? "text-white"
                : "text-blue-400 hover:text-white"
            }`}
        >
          {tag.name}
          {selectedTags.find((t) => t.name === tag.name) && <X size={14} />}
        </button>
      ))}
      {selectedTags.length > 0 && (
        <button
          onClick={() => {
            setSelectedTags([]);
            onFilterChange([]);
          }}
          className="text-white/60 hover:text-white text-sm"
        >
          <X size={14} />
        </button>
      )}
    </div>
  );
};

TagFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TagFilter;
