// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import UrlList from "./components/UrlList";
import Plans from "./components/Plans";
import Features from "./components/Features";
import UrlDetails from "./components/UrlDetails";
import { SignIn, SignUp } from "./components/AuthLayout";
import VerificationPending from "./components/VerificationPending";
import VerifyEmail from "./components/VerifyEmail";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicTermsOfService from "./components/PublicTermsOfService";
import PublicPrivacyPolicy from "./components/PublicPrivacyPolicy";
import Navbar from "./components/Navbar";
import { AuthProvider } from "./components/context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicAccessibility from "./components/PublicAccessibility";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Profile from "./components/Profile";
import MyPlan from "./components/MyPlan";

import { initGA, usePageTracking } from "./utils/analytics";

// Analytics wrapper component
// const AnalyticsWrapper = ({ children }) => {
//   useAnalytics(); // Track page views
//   return children;
// };

const PageTracker = ({ children }) => {
  usePageTracking(); // Track all page views
  return children;
};

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics when the app loads
    initGA();
  }, []);
  return (
    <>
      <GoogleOAuthProvider clientId="725009883467-u66h4cpao2iv5ldib2hv1oe6lj1k2pkj.apps.googleusercontent.com">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <AuthProvider>
          <BrowserRouter>
            <PageTracker>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route
                    path="/verify-pending/:emailAddress"
                    element={<VerificationPending />}
                  />
                  <Route path="/verify-email" element={<VerifyEmail />} />

                  <Route index element={<Home />} />
                  <Route
                    path="/urls"
                    element={
                      <ProtectedRoute>
                        <UrlList />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/details/:shortCode" element={<UrlDetails />} />
                  <Route path="/plans" element={<Plans />} />
                  <Route path="/features" element={<Features />} />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/my-plan"
                    element={
                      <ProtectedRoute>
                        <MyPlan />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/public-privacy-policy"
                    element={<PublicPrivacyPolicy />}
                  />
                  <Route
                    path="/public-terms-of-service"
                    element={<PublicTermsOfService />}
                  />
                  <Route
                    path="/public-accessibility"
                    element={<PublicAccessibility />}
                  />
                  <Route path="/how-it-works" element={<Features />} />
                </Route>
              </Routes>
            </PageTracker>
            <ToastContainer />
          </BrowserRouter>
        </AuthProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
